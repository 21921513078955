import { NgModule } from '@angular/core';
import { Http, HttpModule, RequestOptions } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { ConstantComponent } from './constant.component';
import { ValidationService } from './GenericService/validation.service';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {  NO_ERRORS_SCHEMA } from '@angular/core';
import { SidebarModule } from 'ng-sidebar';
import { AppHttpInterceptor } from './Interceptor/httpInterceptor';
import { NgxImageZoomModule } from 'ngx-image-zoom';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MobileAppMessageComponent } from './mobile-app-message/mobile-app-message.component'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

@NgModule({
    declarations: [
        AppComponent,
        ConstantComponent,
        ValidationService,
        MobileAppMessageComponent        
        
        
        
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpModule,
        HttpClientModule,
        AppRoutingModule,
        
        NgbModule.forRoot(),
        SidebarModule.forRoot(),
        NgxImageZoomModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
    ],
    providers: [AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
          }
    ],
    bootstrap: [AppComponent],
    schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule {
}
