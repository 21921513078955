import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
import { MobileAppMessageComponent } from './mobile-app-message/mobile-app-message.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: './Master/master.module#MasterModule',
        canActivate: [AuthGuard]
    },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'DueReport', loadChildren: './DueReport/DueReport.module#DueReportModule' },    
    { path: 'reset/:id', loadChildren: './ResetPassword/resetpassword.module#ResetPasswordModule' },
   // { path: 'signup', loadChildren: './signup/signup.module#SignupModule' },
    { path: 'register', loadChildren: './register/register.module#RegisterModule' },
    { path: 'forgotpassword', loadChildren: './forgotPassword/forgotpassword.module#ForgotPasswordModule' },
    { path: 'TyreInspectionDailyReport', loadChildren: './TyreInspectionDailyReport/TyreInspectionDailyReport.module#TyreInspectionDRModule' },
    { path: 'WeeklyInspectionSummary', loadChildren: './WeeklyInspectionReport/WeeklyInspectionReport.module#WeeklyInspectionReportModule' },
    { path: 'DailyInspectionSummary', loadChildren: './DailyInspectionReport/DailyInspectionReport.module#DailyInspectionReportModule' },
    { path: 'WhatsAppOrderMessage', component: MobileAppMessageComponent },
   // { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
   // { path: 'un-authorized', loadChildren: './un-authorized/un-authorized.module#UnAuthorizedModule' },                             
    { path: '**', redirectTo: 'not-found' }
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
