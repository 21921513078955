import { Component } from '@angular/core';
import { ConstantComponent } from './constant.component';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthGuard } from './shared/guard/auth.guard';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./../styles/app.css']
})
export class AppComponent {
    production: boolean = environment.production;
    staging: string = environment.envName;
    development: string = environment.envName;
    demo: string = environment.envName;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    
    
    constructor(private idle: Idle, private keepalive: Keepalive, private router: Router, private authService: AuthGuard) {
    // sets an idle timeout of 900 seconds(15min), for testing purposes.
    idle.setIdle(900);
    // sets a timeout period of 900 seconds(15min). after 1800 seconds(30min) of inactivity, the user will be considered timed out.
    idle.setTimeout(900);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.authService.onTokenLoggedout();
      this.router.navigate(['/login']).then(()=>{
        window.location.reload();
      });
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!'
    );
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    //keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
           
        // Live Server
        if (environment.envName === 'prod') {
            ConstantComponent.url = 'https://stagingapi.efleetsystems.com/api';
            ConstantComponent.port = 'https://stagingapi.efleetsystems.com';
           // ConstantComponent.reportUrl = 'http://139.59.37.161:8080';
           ConstantComponent.reportUrl = 'http://apiprod.efleetsystems.com:8080/EFleetReports';
           ConstantComponent.SSRSReportUrl = 'https://mistcloudreports.efleetsystems.com';
        }
        //Staging Server
        else if(environment.envName === "staging") {
            ConstantComponent.url = 'https://stagingapi.efleetsystems.com/api';
            ConstantComponent.port = 'https://stagingapi.efleetsystems.com';
            ConstantComponent.reportUrl = 'http://staging.efleetsystems.com:8080/EFleetReports';
            ConstantComponent.SSRSReportUrl = 'http://ssrsstaging.efleetsystems.com';
        }
        else if(environment.envName === "clientportal") {
            ConstantComponent.url = 'http://clientportalapiv2.efleetsystems.com/api';
            ConstantComponent.port = 'http://clientportalapiv2.efleetsystems.com';
            ConstantComponent.reportUrl = 'http://staging.efleetsystems.com:8080/EFleetReports';
            ConstantComponent.SSRSReportUrl = 'http://ssrsstaging.efleetsystems.com';
        }

        //Development Test Server
        else if(environment.envName === "development") {
            ConstantComponent.url = 'https://mistclouddevapi.efleetsystems.com/api';
            ConstantComponent.port = 'https://mistclouddevapi.efleetsystems.com';
            ConstantComponent.reportUrl = 'http://43.240.65.107:8080/EFleetReports';
            ConstantComponent.SSRSReportUrl = 'http://ssrsstaging.efleetsystems.com';
        } else if (environment.envName === 'demo') {
            ConstantComponent.url = 'https://demoapi.efleetsystems.com/api';
            ConstantComponent.port = 'https://demoapi.efleetsystems.com ';
            ConstantComponent.reportUrl = 'http://150.242.15.70:8080/EFleetReportsDemo';
            ConstantComponent.SSRSReportUrl = 'http://ssrsstaging.efleetsystems.com';
        }
        // QA Server
        else if(environment.envName === "qa") {
            ConstantComponent.url = 'https://qaapiv2.efleetsystems.com/api';
            ConstantComponent.port = 'https://qaapiv2.efleetsystems.com ';
            ConstantComponent.reportUrl = 'http://13.234.101.10:8080/EFleetReports';
            ConstantComponent.SSRSReportUrl = 'http://ssrsstaging.efleetsystems.com';
        }
     //Live Server
     else if(environment.envName === "live") {
        ConstantComponent.url = 'https://mistcloudapi.efleetsystems.co.in/api';
        ConstantComponent.port = 'https://mistcloudapi.efleetsystems.co.in';
       // ConstantComponent.reportUrl = 'http://139.59.37.161:8080';
        ConstantComponent.reportUrl = 'http://mistcloudapi.efleetsystems.co.in:8080/EFleetReports';
        ConstantComponent.SSRSReportUrl = 'https://mistcloudreports.efleetsystems.com';
     }
        //Local Server
        else {                        
            ConstantComponent.url = 'https://mistclouddevapi.efleetsystems.com/api';
            ConstantComponent.port = 'https://mistclouddevapi.efleetsystems.com';
            ConstantComponent.reportUrl = 'http://staging.efleetsystems.com:8080/EFleetReports';
            ConstantComponent.SSRSReportUrl = 'http://ssrsstaging.efleetsystems.com'; 
        }

    }
    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;  
      }

}
